.nav-link {
  font-family: "Rajdhani", sans-serif;
  font-optical-sizing: auto;
  font-size: 30px;
  letter-spacing: 3px;
  margin-bottom: 5px;
}
.navbar-toggler-icon {
  color: white;
  background-color: white;
  border-radius: 7px;
  opacity: 0.3;
  border: none;
}
.me-auto {
  margin-right: 0px !important;
}

.navbar {
  display: flex !important;
  flex-basis: auto;
  flex-direction: row;
  justify-content: flex-end;
}
#nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: sticky !important;
  top: 0;
  z-index: 1000;
  font-family: "Cabin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}
.navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.custom-navbar {
  transition: background-color 0.3s ease;
}

.custom-navbar.scrolled {
  background-color: white;
}

.custom-navbar .nav-link {
  color: #777;
}

.custom-navbar.scrolled .nav-link {
  color: #000;
}
* {
  margin: 0;
  padding: 0;
}
.body1 {
  padding-top: 150px;
  font: normal 18px Sniglet;
  color: white;
  text-align: center;
}

/*general styles*/
h1 {
  font-weight: normal;
  font-size: 36px;
  margin-bottom: 16px;
}
.fun-cube i {
  transform: scale(10);
  opacity: 0.1;
}

#cuboid {
  width: 400px;
  margin: 0 auto;

  perspective: 1000px;
}
#cuboid form {
  transform: translateZ(-20px);

  transform-style: preserve-3d;

  height: 40px;

  transition: all 0.35s;
}

.cuboid-text {
  line-height: 40px;
  height: 40px;
  background: hsl(120, 40%, 20%);
}
.loader {
  background: hsl(120, 40%, 30%);
  animation: phase 1s infinite;
}

.navbar-toggler-icon {
  color: #a76c6c;
  background-color: #ffffff;
  border-radius: 7px;
  opacity: 0.3;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);

  transition: transform 0.3s ease-out;
}

.navbar-toggler-icon:hover {
  transform: scale(0.9);
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.navbar-expand-lg .navbar-collapse {
  justify-content: center;
}
.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 3em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  background-color: white;
  stroke: rgb(0, 0, 0);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}
.hamburger input:checked + svg {
  background-color: white;
  opacity: 0.5;
  border-radius: 6px;
  transform: rotate(-45deg);
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger input:not(:checked) + svg {
  background-color: white;
  opacity: 0.5;
  border-radius: 6px;
  transform: rotate(0deg);
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger input:not(:checked) + svg .line-top-bottom {
  stroke-dasharray: 12 63;
  stroke-dashoffset: 0;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
#modale {
  padding: 8px;
  background: #e9e9e9;
  font-family: "Rajdhani", sans-serif;
  font-optical-sizing: auto;
  font-size: 18px;
  letter-spacing: 3px;
  margin-bottom: 18px;
}
