.language-label {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

.select__control {
  padding: 5px;
  border-color: transparent !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.select__option {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 10px;
}

.select__option--is-selected {
  background-color: #007bff;
  color: #fff;
}

.select__single-value {
  display: flex;
  align-items: center;
}

.select__menu {
  z-index: 9999;
}

.language-label {
  margin-bottom: 8px;
  display: block;
}
.language-container {
  display: block;
  width: 110px;
}
/* Language.css */
.language-container .css-1s2u09g-control {
  padding: 5px;
  border-color: #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.language-container .css-1s2u09g-option {
  display: flex;
  align-items: center;
  padding: 10px;
}

.language-container .css-1s2u09g-option--is-selected {
  background-color: #007bff;
  color: #fff;
}

.language-container .css-1s2u09g-singleValue {
  display: flex;
  align-items: center;
}
.css-zhqgel-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 5px;
  background-color: none !important;
  border-color: none !important;
  border-radius: none !important;
  border-style: none !important;
  border-width: none !important;
  box-shadow: none !important;
}
