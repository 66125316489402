* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0rem;
}

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: -60px -1px 45px;
  letter-spacing: 14px;
  font-size: 27px;
  opacity: 0.5;
}

h2 {
  font-family: "Whisper", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 72px;
  margin: 9px -1px 45px;
  letter-spacing: 9px;
}

h3 {
  font-family: "Cabin Condensed", sans-serif !important;
  font-size: 26px;
  letter-spacing: 6px;
}

.p1 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
}
span {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-optical-sizing: auto;
  font-size: 27px;
}
/* ---------------------------------------testo dopo video--------------------------------- */
.testo1 {
  display: flex;
  max-width: 60%;
  text-align: center !important;
  flex-direction: column;
  margin: 45px auto 10px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  letter-spacing: 2px;
}
#paragrafoacc p {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}
#iknoasaccomodation {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin-bottom: 42px;
  max-width: 800px;
  margin-inline: auto;
  margin-top: -38px;
}
.pplate {
  font-size: 25px;
  font-family: "Rajdhani", sans-serif;
  letter-spacing: 3px;
  margin-top: 42px;
}
#firstpara {
  text-align: center;
}
#sfondotext {
  background-color: rgba(32, 169, 219, 0.541);
  color: white;
}

.active {
  font-weight: bold; /* Cambia il peso del testo per evidenziare il link attivo */
  color: #ff0000; /* Cambia il colore del testo per evidenziare il link attivo */
  /* Aggiungi altri stili desiderati per evidenziare il link attivo */
}
body {
  overflow-x: hidden;
}
#titlerest {
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
}
#letrecard {
  font-family: "Comfortaa", sans-serif;
}

.hover-text {
  font-family: "Comfortaa", sans-serif;
}
#titlefooter {
  font-family: "Comfortaa", sans-serif !important;
  font-size: 20px;
  margin-top: 10px;
}
#btn1 {
  margin-top: 50px !important;
  background-color: #317a8d;
  border: 1px solid #000000;
  box-shadow: 0 2px 10px #0000001a;
  letter-spacing: 12px;
  transition: transform 0.3s, box-shadow 0.6s, background-color 0.3s;
}

#btn1:hover {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 5px 15px #0000003a;
  background-color: #317a8d; /* Cambia il colore al passaggio del mouse */
}
#btn2 {
  width: 100%;
  background-color: rgb(192 108 108);
  letter-spacing: 2px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(203 1 1);
}
#btn2:hover {
  transform: translateY(-2px) translateZ(0); /* Effetto 3D sull'asse Y */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); /* Ombra più pronunciata durante l'hover */
}
#lastitle {
  font-family: "Comfortaa", sans-serif !important;
  font-size: 20px;
  margin-top: 35px !important;
}
