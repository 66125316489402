body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.7;
  color: #2b2e38;
  position: relative;
  height: 100%;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://img.freepik.com/free-vector/light-gray-seamless-nature-patterned-background-vector_53876-166105.jpg?t=st=1717688771~exp=1717692371~hmac=a19990c65980a3784e02d5de8c600075e60e9e9d3add521e32d122f4526a6862&w=740");
  opacity: 0.09;
  z-index: -10;
}
body::before {
  width: 100%;
  height: 100%;
  --s: 200px; /* control the size */
  --c1: #41414153;
  --c2: #bebebe47;
  --c3: #ffffff79;
  opacity: 0.08;
  background: repeating-conic-gradient(from 30deg, #0000 0 120deg, var(--c3) 0 180deg) calc(0.5 * var(--s))
      calc(0.5 * var(--s) * 0.577),
    repeating-conic-gradient(from 30deg, var(--c1) 0 60deg, var(--c2) 0 120deg, var(--c3) 0 180deg);
  background-size: var(--s) calc(var(--s) * 0.577);
}
