#coloresfondocard {
  padding: 20px;
}

.custom-card {
  box-shadow: 0px 25px 16px rgba(0, 0, 0, 0.2);
}
.custom-card img {
  height: 295px;
  object-fit: cover;
}

.img-container {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.img-container img {
  transition: transform 0.3s ease;
}

.img-container:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 6px !important;
}

.img-container:hover::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.img-container .hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.img-container:hover .hover-text {
  opacity: 1;
}
