h2 {
  font-size: 65px;
}
@media (max-width: 1200px) {
  /* Aggiungi i tuoi stili per schermi grandi qui */
}

@media (max-width: 992px) {
  /* Aggiungi i tuoi stili per schermi medi qui */
}

@media (max-width: 768px) {
  #nav {
    border-bottom: none !important;
  }
}

@media (max-width: 576px) {
  #iknosaccomimg {
    width: 300px;
    margin-bottom: 60px;
    margin-top: 20px;
  }
  #iknoasaccomodation {
    font-size: 18px;
  }
  h2 {
    font-style: normal;
    font-size: 40px;
  }
  #firstpara {
    font-size: 17px;
  }
  .letrecard {
    text-align: center;
    font-size: 17px;
  }
  .p1 {
    font-family: "Rajdhani", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 17px;
  }
  #titolo7 {
    font-size: 23px;
  }
}
@media (min-width: 400px) {
  .testo1 {
    display: flex;
    max-width: 79%;

    font-size: 22px;
  }
  h2 {
    font-family: "Whisper", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 35px;
    margin: 9px -1px 45px;
    letter-spacing: 9px;
  }
  #firstpara {
    font-size: 16px;
  }
  #colo {
    width: 242px;

    height: 406px;

    border: 1px solid rgb(230 230 230);
  }
  .letrecard {
    font-size: 16px;
  }
  #iknosaccomimg {
    width: 278px;
  }
  #iknoasaccomodation {
    font-size: 17px;

    margin-bottom: 42px;
    max-width: 800px;

    margin-top: -52px;
  }
  #p1 {
    font-size: 16px;
  }
  .nav-link {
    font-size: 16px;
    letter-spacing: 4px;
    margin-bottom: 10px;

    padding: 4px;
  }
  #title1 {
    display: none;
  }
  #nav {
    border-bottom: none !important;
  }
}
