.footer-dark {
  padding: 34px 31px 32px;
  color: #f0f9ff;
  background-color: #282d32;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark h3:hover {
  color: #fff;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
  color: #fff;
}

.fab {
  padding: 5px;
  transition: 0.5s;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item2.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item2.text {
    margin-bottom: 0;
  }
}

.footer-dark .item2.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 36px;
  width: 70px;
  height: 70px;
  line-height: 65px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 40px 20px;
  color: #fff;
  opacity: 0.75;
  transition: 0.5s;
  transform: scale(0.9);
}

.footer-dark .item.social > a:hover {
  box-shadow: 0 0 15px #fff;
  color: #fff;
}

ul li {
  list-style: none;
  margin: 0 5px;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 20px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .footer-dark .item.social > a {
    height: 69px;
    width: 69px;
    margin: 12px;
    line-height: 65px !important;
    box-shadow: 0 0 0 1px #fff6;
  }
}

#arrowup {
  position: absolute;
  top: 139%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #282d32;
  z-index: 1000;
  width: 169px;
  height: 311px;
  border-radius: 68%;
  display: flex;
  justify-content: center;
}

.admin:hover {
  background-color: #5b5b5b;
  border-radius: 5px;
}

.admin:hover img {
  transform: scale(1.1);
}

.admin:hover h3 {
  color: #ffffff;
}
#logomio {
  display: flex;
  margin-inline: auto;
  margin-top: 9px;
  width: 128px;
  margin-bottom: 4px;
}
