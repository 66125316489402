img {
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay:hover {
  opacity: 1;
}
h1 {
  position: relative;
  z-index: 1; /* Assicura che l'elemento h1 sia posizionato sopra l'overlay */
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
#cardmanuale {
  margin-bottom: 35px !important;
  border: none;
}
