#img2 {
  box-shadow: 5px 5px 10px 2px #747474;
  -webkit-box-shadow: 5px 5px 10px 2px #747474;
  -moz-box-shadow: 5px 5px 10px 2px #747474;
}
#img2 {
  width: 100%;
  height: 100%;
}
#img3 {
  margin-top: 44px;
  width: 100%;
  height: 173px;
  object-fit: cover;
  box-shadow: 5px 5px 10px 2px #747474;
  -webkit-box-shadow: 5px 5px 10px 2px #747474;
  -moz-box-shadow: 5px 5px 10px 2px #747474;
  margin-bottom: 35px;
}
#logocentrale {
  margin-top: 28px;
  width: 160px !important;
  margin-bottom: 48px;
}
#arrowdown {
  margin-top: 93px;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.btn-custom {
  background-image: linear-gradient(to bottom, #f7f7f7, #e0e0e0);
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-custom:hover {
  background-image: linear-gradient(to bottom, #e0e0e0, #cccccc);
  border-color: #aaa;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.btn-custom:active {
  background-image: linear-gradient(to bottom, #cccccc, #b3b3b3);
  border-color: #999;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
#logorist {
  margin-bottom: 20px;
  margin-top: -9px;
  width: 65%;
}
.card {
  position: relative;
}

.card {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover .overlay {
  opacity: 1;
}

.card img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}
#logocandidati {
  width: 142px;
  margin-top: -9px;
  margin-bottom: -34px;
}
#logomio {
  margin-top: -32px;
  width: 170px;
  padding-bottom: -30px;
  margin-bottom: -39px;
}
