.paragprenota {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 4rem;
}
#prenota {
  font-family: "Rajdhani", sans-serif !important;
  font-size: 41px;
  letter-spacing: 16px;
  margin-bottom: 70px;
}

.p9 {
  font-family: "Rajdhani", sans-serif !important;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1rem;
}
#orari {
  font-family: "Comfortaa", sans-serif;
  font-size: 35px;
  letter-spacing: 3px;
}

.table-responsive {
  overflow-x: auto;
}

.paragprenota {
  margin: 20px 0;
}

.custom-table td,
.custom-table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 576px) {
  .custom-table td,
  .custom-table th {
    white-space: normal;
    display: block;
    width: 100%;
    word-wrap: break-word;
  }

  .custom-table th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}
#container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
