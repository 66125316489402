#accomodations {
  margin: 76px 0 145px;
}

.logocentrale {
  margin: 72px 93px;
  display: flex;
  justify-content: center;
}

.rett1 {
  display: flex;
  width: 380px;
  height: 300px;
  border-radius: 5px;
  margin: 80px 50px;
  flex-direction: column;
  text-align: center;
}

.logocandidati {
  display: flex !important;
  justify-content: center;
  margin-bottom: 41px;
  width: 240px;
  margin-inline: auto;
}

#discover {
  margin-bottom: 150px;
}

#row1 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: center;
}

#colo {
  width: 339px;
  display: flex;
  height: 354px;
  gap: 20px;
  border: 1px solid rgb(192, 192, 192);
}

#colonna {
  display: flex;
  justify-content: center;
}

.select-style {
  margin-top: 3px !important;
  padding: 6px 4px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 0px;
  background-color: #f9f9f9;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s, transform 0.3s;
  background-color: rgba(213, 213, 213, 0.686);
  font-family: "Cabin Condensed", sans-serif !important;
}

.error-message,
.success-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}

.error-message {
  color: #ff0000;
  background-color: #ffbaba;
}

.success-message {
  color: #006400;
  background-color: #caffbf;
}

.toggle-mode-btn {
  background: none;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
}

#bisaccia2 {
  height: 57px;
  width: 100%;
  object-fit: cover;
  opacity: 0.1;
  margin-top: 79px;
}

#iknos {
  width: 200px;
  margin-inline: auto;
  display: block;
  margin-top: 100px;
}

/* Footer */
#footer {
  margin-top: 0px;
  padding-bottom: 5px;
  background-color: #000000;
  padding-left: 61px;
  padding-top: 50px;
  padding-right: 61px;
  color: #000000;
}

#iknosaccomimg {
  width: 500px;
  margin-bottom: 65px;
  margin-top: 49px;
  margin-inline: auto;
}

.cookie-modal {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 300px;
  max-height: 80vh;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000;
  overflow: auto;
}

.modal-content {
  text-align: left;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.hovered #icone1 {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.scene {
  width: 140px;
  height: 205px;
  margin: 28px;
  float: left;
  perspective: 2000px;
}

.movie {
  width: 140px;
  height: 205px;
  transform-style: preserve-3d;
  transform: translateZ(-130px);
  transition: transform 0.5s ease;
  position: relative;
}

.movie:hover {
  transform: scale(1.1);
}

.movie .poster,
.movie .info {
  position: absolute;
  width: 140px;
  height: 205px;
  background-color: #fff;
  backface-visibility: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  border-radius: 6px;
}

.movie:hover .poster,
.movie:hover .info {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

.movie .poster {
  transform: translateZ(50px);
  background-size: cover;
  background-repeat: no-repeat;
}

.movie .info {
  transform: rotateY(90deg) translateZ(130px);
  border: 1px solid #d9d9d9;
  font-size: 0.7em;
}

.scene:nth-child(1) .movie .poster {
  background-image: url(../../../public/img/affittacamere/caffe.jpeg);
}

.scene:nth-child(2) .poster {
  background-image: url(../../../public/img/affittacamere/campari.jpeg);
}

.scene:nth-child(3) .poster {
  background-image: url(../../../public/img/affittacamere/fiat.jpeg);
}

.scene:nth-child(4) .poster {
  background-image: url(../../../public/img/affittacamere/film.jpeg);
}

.scene:nth-child(5) .poster {
  background-image: url(../../../public/img/affittacamere/pastajpeg.jpeg);
}

/* Navbar */
.navbar-toggler-icon {
  color: white;
  background-color: white;
  border-radius: 7px;
  opacity: 0.6 !important;
  border: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: white;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
  font-size: 17px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  background-color: #8f929526;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

#colo1 {
  background-image: url("../../../public/img/pasta.png");
  margin: 21px 0;
  margin-inline: auto;
  width: 200px;
  height: 200px;
  display: flex;
  gap: -17px;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#colo1:hover,
#colo1:active {
  transform: scale(0.95);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#colo2 {
  background-image: url("../../../public/img/pizza.png");
  margin: 21px 0;
  margin-inline: auto;
  width: 200px;
  height: 200px;
  display: flex;
  gap: -17px;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#colo2:hover,
#colo2:active {
  transform: scale(0.95);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#colo3 {
  background-image: url("../../../public/img/fish.png");
  margin: 21px 0;
  margin-inline: auto;
  width: 200px;
  height: 200px;
  display: flex;
  gap: -17px;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

#colo3:hover,
#colo3:active {
  transform: scale(0.95);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.btn-primary2 {
  letter-spacing: 4px;
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: #afa0828c;
  --bs-btn-border-color: #fd0d0d00;
  --bs-btn-hover-bg: #afa082;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-color-hover: #000000 !important;
}

#btncandidati {
  display: flex;
  margin-inline: auto;
  margin-bottom: 70px;
  background-color: #1a5c1685;
  border: 1px solid #198754;
  letter-spacing: 3px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.4);
}

#btncandidati:hover {
  background-color: #1a5c16c4;
  border-color: #1a5c1685;
  color: rgb(254, 254, 254);
  transform: scale(1.05);
}
#icone1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.hamburger {
  display: none;
}

@media (max-width: 992px) {
  .hamburger {
    display: block;
  }
}
