.boxrestaurant {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#colo1 {
  border-radius: 9px;

  margin-inline: auto;
  width: 200px;
  display: flex;
  height: 200px;
  gap: -17px;

  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/* Aggiungi questo codice CSS al tuo file CSS (ristorante.css) */
/* Aggiungi questo codice CSS al tuo file CSS (ristorante.css) */
/* Aggiungi questo codice CSS al tuo file CSS (ristorante.css) */

/* Stile per l'hover state dei div contenitori dei pulsanti */
.button-container:hover {
  transform: scale(0.95); /* Schiaccia del 5% */
  transition: transform 0.3s ease; /* Aggiungi una transizione fluida */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Cambia l'ombra al passaggio del mouse */
}

/* Stile specifico per l'hover state dei pulsanti */
.button-container:hover button {
  color: #e1e1e1; /* Cambia il colore del testo al passaggio del mouse */
}
